import * as React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
// @ts-ignore: no types for Disqus plugin
import { Disqus } from 'gatsby-plugin-disqus';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import Layout from '../layout';
import Seo from '../seo';
import Hero from '../hero';
import Tags from '../tags';
import padding from '../../util/padding';

export type Props = {
  pageContext: {
    id: string;
  };
  path: string;
  data: {
    site: {
      siteMetadata: {
        siteUrl: string;
      };
    };
    mdx: {
      excerpt: string;
      frontmatter: {
        title: string;
        date: string;
        ogDate: string;
        tags: Array<string>;
        hero: {
          darken?: string;
          author?: string;
          unsplashUser?: string;

          position?: string;
          file: {
            childImageSharp: {
              fluid: {
                src: string;
                srcSet: string;
              };
              ogImage: {
                src: string;
                width: number;
                height: number;
              };
            };
          };
        };
      };
      body: string;
    };
  };
};

const Blockquote: React.FunctionComponent = ({ children }) => (
  <blockquote
    css={css`
      position: relative;
    `}
  >
    <FontAwesomeIcon
      icon={faQuoteLeft}
      color="#a70d00"
      css={css`
        position: absolute;
        left: -1.5em;
        top: 0;
      `}
    />
    {children}
  </blockquote>
);

const components = {
  blockquote: Blockquote,
};

const PostPage = ({ data, path, pageContext }: Props) => {
  const { mdx } = data;
  const { frontmatter, body, excerpt } = mdx;
  const { title, date, ogDate, tags, hero } = frontmatter;
  const { fluid, ogImage } = hero.file.childImageSharp;

  const url = `${data.site.siteMetadata.siteUrl}${path}`;

  const shareProps = {
    url,
    css: css`
      margin-right: 8px;
    `,
  };

  return (
    <Layout withHero>
      <Seo
        title={title}
        description={excerpt}
        article={{ tags: frontmatter.tags, publishedTime: ogDate }}
        image={ogImage}
      />
      <Hero
        {...fluid}
        darken={hero.darken}
        unsplashUser={hero.unsplashUser}
        author={hero.author}
        title={title}
        position={hero.position}
      />
      <article css={padding}>
        <h1
          css={css`
            margin: 1.5em 0 0.25em 0;
          `}
        >
          {title}
        </h1>
        <h3
          css={css`
            font-size: 16px;
            color: #555;
            margin-bottom: 15px;
          `}
        >
          <span
            css={css`
              margin-right: 8px;
            `}
          >
            {date}
          </span>
          <Tags tags={tags} />
        </h3>
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        <div
          css={css`
            margin-bottom: 1em;
          `}
        >
          <h3
            css={css`
              margin: 0;
            `}
          >
            Share
          </h3>
          {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <FacebookShareButton {...shareProps}>
            <FacebookIcon size={24} />
          </FacebookShareButton>
          {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <TwitterShareButton {...shareProps}>
            <TwitterIcon size={24} />
          </TwitterShareButton>
          {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <PinterestShareButton {...shareProps}>
            <PinterestIcon size={24} />
          </PinterestShareButton>
          {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <RedditShareButton {...shareProps}>
            <RedditIcon size={24} />
          </RedditShareButton>
          {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <WhatsappShareButton {...shareProps}>
            <WhatsappIcon size={24} />
          </WhatsappShareButton>
          {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
          <EmailShareButton {...shareProps}>
            <EmailIcon size={24} />
          </EmailShareButton>
        </div>
        <h3
          css={css`
            margin: 0;
          `}
        >
          Comments
        </h3>
        <Disqus
          config={{
            identifier: pageContext.id,
            url,
            title,
          }}
        />
      </article>
    </Layout>
  );
};

export default PostPage;

export const postQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        ogDate: date(formatString: "YYYY-MM-DD")
        tags
        hero {
          darken
          author
          unsplashUser
          position
          file {
            childImageSharp {
              fluid(quality: 75, jpegProgressive: true) {
                src
                srcSet
              }
              ogImage: fixed(
                fit: COVER
                width: 1200
                height: 630
                jpegProgressive: true
              ) {
                src
                width
                height
              }
            }
          }
        }
      }
      body
    }
  }
`;
