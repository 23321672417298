import * as React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

export type Props = {
  tags: Array<string>;
};

const Tags: React.FunctionComponent<Props> = ({ tags }) => (
  <>
    {tags.map(t => (
      <Link
        key={t}
        to={`/tag/${t}`}
        css={css`
          margin-right: 8px;
          color: #a70d00;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        `}
      >{`#${t}`}</Link>
    ))}
  </>
);

export default Tags;
